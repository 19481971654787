<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
            icon
            class="btn-primary"
            color="primary"
            @click="setShowEditDialog(true)">
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- Info -->
      <v-col cols="12">

        <v-row no-gutters>

          <!-- Losses -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.material_losses') }} : </span>
            <span>{{ supplier.factory_performances.losses }}</span>
          </v-col>

          <!-- Transportations -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.transportations') }}: </span>
            <span v-if="supplier.factory_performances.transportations && supplier.factory_performances.transportations.length > 0">
              <v-chip label v-for="transport of supplier.factory_performances.transportations" :key="transport">{{ transport }}</v-chip>
            </span>
            <span v-else>{{ $t('common.misc.NA') }}</span>
          </v-col>

          <!-- Heat type -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.heatType') }}: </span>
            <span>{{ supplier.factory_performances.heatType }}</span>
          </v-col>

          <!-- Green Electricity Equipment -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.greenElectricityEquipmentKind') }}: </span>
            <span v-if="supplier.factory_performances.hasGreenElectricityEquipment">{{ supplier.factory_performances.greenElectricityEquipmentKind }}</span>
            <span v-else>{{ $t('common.misc.none') }}</span>
          </v-col>

          <!-- Electricity consumption -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.electricityConsumptionPerMonth') }}: </span>
            <span>{{ supplier.factory_performances.electricityConsumptionPerMonth }} KWh</span>
          </v-col>

          <!-- In house Treatment System -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.inHouseEffluentKind') }}: </span>
            <span v-if="supplier.factory_performances.hasInHouseEffluentTreatmentSystem">{{ supplier.factory_performances.inHouseEffluentKind }}</span>
            <span v-else>{{ $t('common.misc.none') }}</span>
          </v-col>

          <!-- Energy Efficient Investment -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.energyEfficientKind') }}: </span>
            <span v-if="supplier.factory_performances.hasEnergyEfficientInvestment">{{ supplier.factory_performances.energyEfficientKind }}</span>
            <span v-else>{{ $t('common.misc.none') }}</span>
          </v-col>

          <!-- Has Program Impact Participation -->
          <v-col cols="12" class="my-1">
            <span>{{ $t('suppliers.tabs.factory-performances.labels.hasProgramImpactParticipation') }}: </span>
            <span>{{ supplier.factory_performances.hasProgramImpactParticipation }}</span>
          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <SupplierPerformanceDialog
      v-if="showEditDialog"
      :value="showEditDialog"
      :item="supplier.factory_performances"
      :supplier-id="supplier.id"
      @close="(needRefresh) => setShowEditDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>
export default {
  name: "SupplierFactoryPerformancesTab",

  components: {
    SupplierPerformanceDialog: () => import("@/components/Suppliers/FactoryPerformance/SupplierPerformanceDialog")
  },

  mixins: [],

  props: {
    supplier: {type: Object, default: () => null}
  },

  data() {
    return {
      showEditDialog: false,
    }
  },

  methods: {
    getData() {
      this.$emit('refresh');
    },

    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh)
        this.getData();
      this.showEditDialog = val;
    }
  }
}
</script>

<style scoped>

</style>
